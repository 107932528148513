<div class="container">

    <section id="bg-image" data-type="background" data-speed="5" style="background: url(red_7.jpg) no-repeat;"
        class="page-header-padding bg-white f-cinebook-section parallax-bg">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div class="containt-box chi-siamo-bold">
                    <h1>ENTRA NEL CLUB</h1>

                    <p>
                        Entrando nel Club <span style="color:#000000">Cinebooking</span> (FUNZIONE ESCLUSIVAMENTE PER LE FIGURE ARTISTICHE) usufruirai per
                        un anno dell’account a pagamento (NO ABBONAMENTO NO RINNOVO AUTOMATICO) per visionare eventuali
                        tue candidature a tutti i match lavoro ad un costo di euro 49,00 l’anno che sarà valido per 365
                        giorni dal giorno in cui accederai al servizio VISIONE a pagamento. L’account Club proporrà la
                        tua scheda tra le prime candidate, inoltre riceverai inviti ad eventi “Cinebooking” di cui siamo
                        sponsor: cene di gala, workshop, fiere, prime di film ed opere teatrali (in alcuni casi sarà
                        prevista una quota addizionale per parteciparvi a coloro che hanno richiesto la partecipazione).
                    </p>
                    <a routerLink="/my-account" class="btn btn-primary btn-green btn-entra-nel-club" style="color: #FFF;">ENTRA NEL CLUB</a>
                </div>
            </div>
        </div>
    </section>
</div>