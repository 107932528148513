<div class="container">
    <section class="about-cerca page-header-section page-header-padding bg-white">

        <div class="row">
            <div class="col-lg-12">
                <div class="about-info">
                    <h2 style="text-align:center;margin-bottom:5px;">DIVENTA <span style="color: #db2557;">PREMIUM</span></h2>
                    <h4 style="text-align:center"><span style="color: #db2557;"><i>Aiuta la tua carriera!</i></span></h4>
                    <br />
                    

                    <div class="alert alert-danger alert-block" style="top:0rem!important;z-index: 10!important;" *ngIf="overLimit">
                        <button type="button" class="colse" data-dismiss="alert" (click)="overLimit = false">X</button>
                        <strong>Solo alcuni match lavoro sono in visione gratuita.</strong>
                        <div>Sottoscrivi un abbonamento per visionarli tutti senza limitazioni, avrai più opportunità di scelta e di lavoro.</div>
                    </div>

                    <div class="alert alert-danger alert-block" style="top:0rem!important;z-index: 10!important;" *ngIf="subscribeYet">
                        <button type="button" class="colse" data-dismiss="alert" (click)="subscribeYet = false">X</button>
                        <strong>Sei già un nostro abbonato.</strong>
                        <div>Non è possibile procedere con l'abbonamento poichè il suo account risulta già come un account premium.</div>
                    </div>


                    <div class="pntmd-form-heading-text-1 pntmd-hidden-mobile">Cinebooking è un’azienda Italiana, una web/app dove è
                        possibile registrarsi, scaricare l’app ed eseguire una scheda proposta o ricerca in modo assolutamente gratuito. La
                        visione di alcuni match è gratuita, ma Cinebooking prevede una formula Premium che consente di visualizzare tutte le
                        offerte di lavoro, proponendo due opzioni in abbonamento, che qualora l’utente volesse visionare tutte le offerte di
                        lavoro ricevute in match definito “job alert” dovrebbe affrontare un costo di 5,90 euro mensili o 49,00 euro
                        annuali. Cinebooking offre un servizio di metamotore di ricerca raccogliendo il 90% circa delle offerte disponibili
                        nel mercato del lavoro Italiano nei settori del Cinema, Televisione, Pubblicità, Eventi, Radio, Moda, Musica,
                        Teatro. Attualmente, Cinebooking ha generato in tutta Italia oltre un milione di match lavoro ricevendo centinaia di
                        recensioni positive e testimonianze di candidati che hanno trovato lavoro attraverso il nostro match.
                    </div>
                    <br />
                    <br />

                    <div class="premium-container">
                        <div class="premium-card">
                            <div class="title">
                                <div class="title-left"><span style="color: #db2557;">PREMIUM</span> ANNUALE</div>
                                <div class="title-right"><span style="text-align:right;font-weight:700;">49,00 €/Anno</span></div>
                            </div>
                            <hr />
                            
                            <div class="description">
                                <ul>
                                    <li>“FREE” GRATUITO visioni solo alcuni match, perderai molte opportunità. </li>
                                    <li>PREMIUM ANNUALE visioni tutte le offerte di lavoro per un periodo di 365 giorni dalla data di attivazione.</li>
                                    <li>Il rinnovo è automatico ma puoi annullarlo in qualsiasi momento.</li>
                                    <li>Risparmi 20,00 euro rispetto al pacchetto PREMIUM MENSILE.</li>
                                    <li>IT Iva inclusa</li>
                                    <li>Riceverai inviti ad eventi esclusivi.</li>
                                </ul>
                            </div>
                            <div>
                                <button class="btn-pay" (click)="annuale()">PROSEGUI</button>
                            </div>
                    
                        </div>
                        <div class="premium-card">
                            <div class="title">
                                <div class="title-left"><span style="color: #db2557;">PREMIUM</span> MENSILE</div>
                                <div class="title-right"><span style="text-align:right;font-weight:700;">5,90 €/Mese</span></div>
                            </div>
                            <hr />
                            <div class="description">
                                <ul>
                                    <li>“FREE” GRATUITO visioni solo alcuni match, perderai molte opportunità.</li>
                                    <li>PREMIUM MENSILE visioni tutte le offerte di lavoro per un periodo di 31 giorni dalla data di attivazione.</li>
                                    <li>Il rinnovo è automatico ma puoi annullarlo in qualsiasi momento.</li>
                                    <li>Paghi 20,00 euro in più rispetto al pacchetto PREMIUM ANNUALE.</li>
                                    <li>IT Iva inclusa.</li>
                                    <li style="opacity: 0;"></li>
                                </ul>
                            </div>
                            <div>
                                <button class="btn-pay-sec" (click)="mensile()">PROSEGUI</button>
                            </div>
                    
                        </div>
                    </div>

                    <br />
                    <br />

                </div>
            </div>
        </div>
    </section>
</div>